<template>
	<div class="p-grid panel-demo dashboard">
        <div class="p-col-12">
            <div class="card">
                <Toast/>
				<Toolbar>
                    <template v-slot:left>
						<h4>Envío y Recepción de Muestras</h4>
					</template>
					<template v-slot:right>
                        <Button :disabled="id_estatus == 4  || id_estatus == 3 " label="Guardar Cambios" @click="SaveSendSimple" icon="pi pi-check" class="p-button-raised p-mr-2" />
					</template>
				</Toolbar>
			
                <div class="p-d-flex">
                    <div class="p-fluid p-col-12 p-md-2">
                        <div class="card summary">
                            <span class="title">Folio Muestra</span>
                            <InputNumber  id="desc" v-model="Id_muestra"  />
                            <Button id="cargar" href='cargar' label="Buscar" class="p-button-raised p-mr-2 myLink" @click="GetMuestra" />
                            <!-- <span class="detail">4524</span> -->
                        </div>
                    </div>
                    <div class="p-fluid p-col-12 p-md-2">
                        <div class="card summary">
                            <span class="title">Doctor</span>
                            <span class="detail">{{muestra.doctor}}</span>
                        </div>
                    </div>
                    <div class="p-fluid p-col-12 p-md-2">
                        <div class="card summary">
                            <span class="title">Paciente</span>
                            <span class="detail">{{muestra.paciente}}</span>
                        </div>
                    </div>                
                    <div class="p-fluid p-col-12 p-md-2">
                        <div class="card summary">
                            <span class="title">Servicio</span>
                            <span class="detail">{{nombreservico1}}</span>
                        </div>
                    </div>
                    <div class="p-fluid p-col-12 p-md-2">
                        <div class="card summary">
                            <span class="title">Estatus</span>
                            <span class="detail">{{muestra.estatu}}</span>
                        </div>
                    </div>
                </div>
            </div>
		</div>
		<div class="p-col-6">
			<div class="card">
                <Toolbar>
					<template #left>
                        <h5><strong>Envío de Kit</strong></h5>
					</template>
					<template #right>
                        <h5><strong>SG Cells -> Consultorio Médico</strong></h5>
					</template>
				</Toolbar>
                <h5></h5>
				<div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-6">
                        <label for="fechaEnvio">Fecha envío de kit *</label>
                        <Calendar id="dateformat" :disabled="id_estatus == 4 || id_estatus == 3 "  dateFormat="dd/mm/yy" :showIcon="true" :showButtonBar="true" v-model="calendarValueEnvio" ></Calendar>
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <label for="responsableEnvio">Responsable del envío *</label>
                        <InputText :disabled="id_estatus == 4 || id_estatus == 3 " v-model="ResponsableEnvio" id="responsableEnvio" type="text" />
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <label for="medioTransporte">Medio de transporte *</label>
                        <Dropdown :disabled="id_estatus == 4 || id_estatus == 3 " id="medioTransporte" v-model="dropdownTransporteEnvio" :options="medioTransporte" optionLabel="nombre" placeholder="Seleccionar un medio de transporte..."></Dropdown>
                    </div>
                        <div class="p-field p-col-12 p-md-6">
                        <label for="medioTransporte">Nombre del medio de transporte *</label>
                        <InputText :disabled="id_estatus == 4 || id_estatus == 3 " v-model="NombretransporteEnvio" id="medioTransporte" type="text"/>
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <label for="guiaEnvio">Guía / Referencia</label>
                        <InputText :disabled="id_estatus == 4 || id_estatus == 3 " v-model="ReferenciaEnvio" id="guiaEnvio" type="text"/>
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <label for="fechaLlegada">Fecha llegada del kit</label>
                        <Calendar :disabled="id_estatus == 4 || id_estatus == 3 " :showIcon="true" dateFormat="dd/mm/yy" :showButtonBar="true" v-model="calendarValuellegadaEnvio"></Calendar>
                    </div>
                    <div class="p-field p-col-12 p-md-12">
						<label for="condiciones">Condiciones generales del kit (Cómo le llegó al doctor el kit)</label>
						<Textarea :disabled="id_estatus == 4 || id_estatus == 3 " id="condiciones" v-model="condicionesMuestraEnvio" required="false" rows="2" cols="20" />
					</div>
                    <div class="p-field p-col-12 p-md-6">
                         <!-- <label for="fechaLlegada">Fecha real de toma Solo (Lun-Mar-Mier)</label>                          -->
                        <h6 v-if="clave_estado=='NLE'">Fecha real de toma. Sólo de Lun a Vie</h6>
                        <h6 v-else>Fecha real de toma. Sólo (Lun-Mar-Mier)</h6>
                        <Calendar :disabled="id_estatus == 4  || id_estatus == 3 " :showIcon="true"  dateFormat="dd/mm/yy" :showButtonBar="true" v-model="calendarValueRealEnvio"></Calendar>
                    </div>
				</div>
			</div>
		</div>
        <div class="p-col-6">
			<div class="card">
                <Toolbar>
					<template #left>
                        <h5><strong>Recepción de Muestras</strong></h5>
					</template>
					<template #right>
                        <h5><strong>Consultorio Médico -> SG Cells</strong></h5>
					</template>
				</Toolbar>
                <h5></h5>
				<div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-6">
                        <label for="fechaEnvio">Fecha de envío de muestras *</label>
                        <Calendar :disabled="id_estatus == 4  || id_estatus == 3 "  dateFormat="dd/mm/yy" :showIcon="true" :showButtonBar="true" v-model="FechaEnvioRecep"></Calendar>
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <label for="responsableEnvio">Responsable del envío *</label>
                        <InputText :disabled="id_estatus == 4  || id_estatus == 3 "  v-model="ResponsableRecep" id="responsableEnvio" type="text" />
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <label for="medioTransporte">Medio de transporte *</label>
                        <Dropdown :disabled="id_estatus == 4  || id_estatus == 3 "  id="medioTransporte" v-model="dropdownTransporteRecep" :options="medioTransporte" optionLabel="nombre" placeholder="Seleccionar un medio de transporte..."></Dropdown>
                    </div>
                        <div class="p-field p-col-12 p-md-6">
                        <label for="medioTransporte">Nombre del medio de transporte *</label>
                        <InputText :disabled="id_estatus == 4  || id_estatus == 3 "  v-model="NombretransporteRecep" id="medioTransporte" type="text"/>
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <label for="guiaRecepcion">Guía / Referencia</label>
                        <InputText :disabled="id_estatus == 4  || id_estatus == 3 "  v-model="ReferenciaRecep" id="guiaRecepcion" type="text"/>
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <label for="fechaLlegada">Fecha llegada de las muestras</label>
                        <Calendar :disabled="id_estatus == 4  || id_estatus == 3 " :showIcon="true" dateFormat="dd/mm/yy" :showButtonBar="true" v-model="calendarValueLlegadaRecep"></Calendar>
                    </div>
                    <div class="p-field p-col-12 p-md-12">
						<label for="condiciones">Condiciones generales de la muestra</label>
						<Textarea :disabled="id_estatus == 4 || id_estatus == 3 "  id="condiciones"  v-model="condicionesMuestraRecep" required="false" rows="2" cols="20" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
// import DoctorService from '../service/DoctorService';
import MuestraService from '../service/MuestraService';
// import PadecimientoService from '../service/PadecimientoService';
// import ServicioService from '../service/ServicioService';
import EnvioMuestraService from '../service/EnvioMuestraService';
import SolicitudMuestraService from '../service/SolicitudMuestraService';
 import moment from 'moment';
	export default {
		data() {
			return {
                porcdescuento: 0,
                text1:null,
                text2:false,
                Id_muestra:0,
                motivodescuento: null,
                descuentoDialog: false,
                servicios: null,
                selectedServicios: null,
                selectedAutoValueDoctor: null,
                autoFilteredValueDoctor: [],
                selectedAutoValuePadecimiento: null,
                autoFilteredValuePadecimiento: [],
                calendarValue: null,
                calendarValueEnvio: null,
                dropdownTransporteEnvio:null,
                ResponsableEnvio:null,
                NombretransporteEnvio:null,
                ReferenciaEnvio:null,
                calendarValuellegadaEnvio:null,
                condicionesMuestraEnvio:"",
                calendarValueRealEnvio:null,
                FechaEnvioRecep:null,
                ResponsableRecep:null,
                dropdownTransporteRecep:null,
                NombretransporteRecep:null,
                ReferenciaRecep:null,
                calendarValueLlegadaRecep:null, 
                condicionesMuestraRecep:"",
                submitted: false,
                condicionesMuestra: null,
                dropdownTransportes: [
					{nombre: 'Transporte propio',value:1},
					{nombre: 'Transportación terrestre',value:2},
                    {nombre: 'Transportación aérea',value:3},
                    {nombre: 'Otro medio de transporte',value:4},
				],
				dropdownTransporte: null,
				dropdownServicios: [
					{nombre: 'Cultivo de Grasa'},
					{nombre: 'Cultimo de Médulo Ósea'},
                    {nombre: 'Cultivo de Sangre Periférica'},
                    {nombre: 'Cultivo de Piel'}
				],
                dropdownServicio: null,
                muestra:{},
                muestraPost1:[],
                muestraPost2:[],
                fecha_llegada:null,
                fecha_real_toma:null, 
                fecha_llegada2:null,
                fecha_real_toma2:null, 
                OptionEnvioMedioTran:null,
                 OptionRecepMedioTran:null, 
                clave_estado:null,
                id_estatus:null,
                serviciosN:null,
                nombreservico:null,
                nombreservico1:null,
                EstatusCambio:[],
                medioTransporte:null,
			}
        },
        // doctorService: null,
        // padecimientoService: null,
        // servicioService: null,
        muestraservice:null,
        enviomuestraservice:null,
        ResponseEnvio:null,
        ResponseRecepcion:null,
         solicitudmuestraService:null,
        id_envioMuestra:null,
		created() {
            // this.doctorService = new DoctorService();
            // this.padecimientoService = new PadecimientoService();
            // this.servicioService = new ServicioService();
            this.muestraservice = new MuestraService();
            this.enviomuestraservice = new EnvioMuestraService();
		},
		mounted() {
            // this.doctorService.getDoctoresCombo().then(data => this.autoValue = data);
            // this.padecimientoService.getPadecimientos().then(data => this.autoValue = data);
            // this.servicioService.getServiciosPrecios().then(data => this.servicios = data);
             this.solicitudmuestraService = new SolicitudMuestraService();
              this.muestraservice.getmMediotransportes().then(data => this.medioTransporte = data);
		},
        methods: {
		 
            openNew() {
                this.submitted = false;
                this.descuentoDialog = true;
            },
            hideDialog() {
			    this.descuentoDialog = false;
			    this.submitted = false;
            },
            saveDescuento() {
                this.submitted = true;
                this.descuentoDialog = false;                           
            },
            GetMuestra(){
                if(this.Id_muestra >0){
                    this.muestraservice.getMuestrasID(this.Id_muestra).then(data => 
                    {
                         if(typeof(data.data1[0]) !='undefined'){
                        this.muestra = data.data1[0];
                        this.muestra.paciente = data.data1[0].paciente+" "+data.data1[0].pacienteApP+" "+data.data1[0].pacienteApM;
                        this.clave_estado=data.data1[0].clave_estado;
                        this.id_estatus=data.data1[0].id_estatus;
                        this.serviciosN=data.data2;
                        this.nombreservico=null;

                        if(this.serviciosN != null){
                        for(var id1 in this.serviciosN){                           
                            this.nombreservico=this.nombreservico+' '+this.serviciosN[id1].nombre;
                            this.nombreservico1=this.nombreservico.replace("null", "");
                        }
                        }
                        console.log(this.id_estatus);
                        console.log(this.muestra);
                        }else{
                            this.muestra.doctor = ''; 
                            this.muestra.paciente = ''; 
                            this.nombreservico1 = ''; 
                            this.muestra.estatu = ''; 
                            this.$toast.add({severity:'warn', summary: 'Mensaje', detail: 'No existe el folio de Muestra ', life: 4000});
                            this.Id_muestra=0;
                        }
                        
                        });     
                    
                   
                    
                   
                    var dias = 1;
 
                    // if(this.muestra != null){
                    this.enviomuestraservice.getUEnvioMuestraID(this.Id_muestra).then(data => {
                        this.ResponseEnvio = data[0]
                         if(this.ResponseEnvio != null){

                            // this.OptionEnvioMedioTran=this.ResponseEnvio.id_medio_transporte;

                            for(var i in this.medioTransporte){
                                
                                if(this.ResponseEnvio.id_medio_transporte==this.medioTransporte[i].id_medio_transporte)
                                {                                    
                                    this.dropdownTransporteEnvio = this.medioTransporte[i];     
                                    // console.log(this.dropdownTransporteEnvio);                               
                                }                  	
                            } 
                            
                            // this.calendarValueEnvio=this.ResponseEnvio.fecha_envio;
                            this.calendarValueEnvio=new Date(this.ResponseEnvio.fecha_envio);
                            this.calendarValueEnvio.setDate(this.calendarValueEnvio.getDate() + dias);

                            // this.calendarValuellegadaEnvio=this.ResponseEnvio.fecha_llegada;
                            if(this.ResponseEnvio.fecha_llegada != null){
                                this.calendarValuellegadaEnvio=new Date(this.ResponseEnvio.fecha_llegada);
                                this.calendarValuellegadaEnvio.setDate(this.calendarValuellegadaEnvio.getDate() + dias);

                            }else{
                                this.calendarValuellegadaEnvio=this.ResponseEnvio.fecha_llegada;                           
                            }
                            this.ResponsableEnvio=this.ResponseEnvio.responsable_envio;
                            // this.dropdownTransporteEnvio=this.ResponseEnvio.id_medio_transporte;
                            this.NombretransporteEnvio=this.ResponseEnvio.nombre_medio_trans;
                            this.ReferenciaEnvio=this.ResponseEnvio.guia;
                            this.condicionesMuestraEnvio=this.ResponseEnvio.condiciones;
                            // this.calendarValueRealEnvio=this.ResponseEnvio.fecha_real_toma;
                            if(this.ResponseEnvio.fecha_real_toma != null){
                                this.calendarValueRealEnvio=new Date(this.ResponseEnvio.fecha_real_toma);
                                this.calendarValueRealEnvio.setDate(this.calendarValueRealEnvio.getDate() + dias);

                            }else{
                                this.calendarValueRealEnvio=this.ResponseEnvio.fecha_real_toma;                           
                            }

                        }else{
                            this.calendarValueEnvio=null;
                            this.calendarValuellegadaEnvio=null;
                            this.ResponsableEnvio=null;
                            this.dropdownTransporteEnvio=null;
                            this.NombretransporteEnvio=null;
                            this.ReferenciaEnvio=null;
                            this.condicionesMuestraEnvio=null;
                            this.calendarValueRealEnvio=null;
                        }
                    });
                    // console.log(this.ResponseEnvio);    
                       
                            this.enviomuestraservice.getURecepMuestraID(this.Id_muestra).then(data => {
                                this.ResponseRecepcion = data[0]
                                if(this.ResponseRecepcion != null){ 

                                // this.OptionRecepMedioTran=this.ResponseEnvio.id_medio_transporte;

                                for(var i1 in this.medioTransporte){
                                    
                                    if(this.ResponseRecepcion.id_medio_transporte==this.medioTransporte[i1].id_medio_transporte){
                                        
                                        this.dropdownTransporteRecep = this.medioTransporte[i1];
                                        
                                    }                  	
                                } 

                                // this.FechaEnvioRecep=this.ResponseRecepcion.fecha_envio;
                                this.FechaEnvioRecep=new Date(this.ResponseRecepcion.fecha_envio);
                                this.FechaEnvioRecep.setDate(this.FechaEnvioRecep.getDate() + dias);

                                // this.calendarValueLlegadaRecep=this.ResponseRecepcion.fecha_llegada;
                                if(this.ResponseRecepcion.fecha_llegada != null){
                                    this.calendarValueLlegadaRecep=new Date(this.ResponseRecepcion.fecha_llegada);
                                    this.calendarValueLlegadaRecep.setDate(this.calendarValueLlegadaRecep.getDate() + dias);

                                }else{
                                    this.calendarValueLlegadaRecep=this.ResponseRecepcion.fecha_llegada;                           
                                }
                                this.ResponsableRecep=this.ResponseRecepcion.responsable_envio;
                                // this.dropdownTransporteRecep=this.ResponseRecepcion.id_medio_transporte;
                                this.NombretransporteRecep=this.ResponseRecepcion.nombre_medio_trans;
                                this.ReferenciaRecep=this.ResponseRecepcion.guia;
                                this.condicionesMuestraRecep=this.ResponseRecepcion.condiciones;
                                
                            }else{

                                this.FechaEnvioRecep=null;
                                this.calendarValueLlegadaRecep=null;
                                this.ResponsableRecep=null;
                                this.dropdownTransporteRecep=null;
                                this.NombretransporteRecep=null;
                                this.ReferenciaRecep=null;
                                this.condicionesMuestraRecep=null;            
                            }
                        });

                       

                        
                    // }    document.querySelector('#cargar').click();
                    // document.querySelector('#cargar').click();
                    // window.onload = function(){
                    //     var button = document.getElementById('cargar');
                    //     button.form.submit();
                    // }

                    // document.getElementById("cargar").dblclick();
                    // document.getElementyById("cargar").click(); 
                    // var targLink    = document.getElementById ("cargar");
                    // var clickEvent  = document.createEvent ('MouseEvents');
                    // clickEvent.initEvent ('dblclick', true, true);
                    // targLink.dispatchEvent (clickEvent);
                    // document.getElementById('btnStartVisit').dispatchEvent(new MouseEvent("click"));

                }               
            },
            
            SaveSendSimple(){
                if (this.Id_muestra !=0)
                {
                    if (this.calendarValueEnvio != null )
                    {   
                        let continuar = true;

                        if(this.ResponsableEnvio == null || this.ResponsableEnvio == ''){
                            this.$toast.add({severity:'warn', summary: 'Mensaje', detail: 'El responsable del envío del kit es requerido.', life: 3000});
                            continuar = false;
                        }
                        else if(this.dropdownTransporteEnvio == null){
                            this.$toast.add({severity:'warn', summary: 'Mensaje', detail: 'El medio de transporte del envío del kit es requerido.', life: 3000});
                            continuar = false;
                        }
                        else if(this.NombretransporteEnvio == null || this.NombretransporteEnvio == ''){
                            this.$toast.add({severity:'warn', summary: 'Mensaje', detail: 'El nombre del medio de transporte del envío del kit es requerido.', life: 3000});
                            continuar = false;
                        }

                        if(continuar){
                        if(this.ResponseEnvio == null)
                        {
                            if(this.calendarValuellegadaEnvio !=null)
                            {
                                this.fecha_llegada=this.calendarValuellegadaEnvio.toISOString().slice(0,10)
                            }

                            if(this.calendarValueRealEnvio !=null)
                            {
                                this.fecha_real_toma=this.calendarValueRealEnvio.toISOString().slice(0,10)
                            }
                           
                           //Compara 
                            console.log('fechas',this.computedDates(this.calendarValueEnvio),this.muestra.fecha_deseada_toma);
                            if(this.fecha_llegada == null )
                            {
                                this.muestraPost1.push({                                  
                                    "id_muestra":this.Id_muestra,
                                    "fecha_envio":this.calendarValuellegadaEnvio.toISOString().slice(0,10),
                                    "fecha_llegada":this.fecha_llegada,
                                    "responsable_envio":this.ResponsableEnvio,
                                    "id_medio_transporte":this.dropdownTransporteEnvio.id_medio_transporte,
                                    "nombre_medio_trans":this.NombretransporteEnvio,
                                    "guia":this.ReferenciaEnvio,
                                    "condiciones":this.condicionesMuestraEnvio,
                                    "fecha_real_toma":this.fecha_real_toma,
                                });
                                    
                                this.enviomuestraservice.createEnvioMuestra(this.muestraPost1[0]).then(data => 
                                {
                                    this.ResponseEnvio = data
                                    this.id_envioMuestra=this.ResponseEnvio.id_envio_muestra;
                                    this.$toast.add({severity:'success', summary: 'Exito', detail: 'Se ha guardado correctamente. Envío de Kit.', life: 3000});
                                    console.log(this.id_envioMuestra);

                                    this.EstatusCambio.push({
                                        "id_muestra":this.muestra.id_muestra,
                                        "id_estatus":2,
                                        "motivo_descuento":this.muestra.motivo_descuento,
                                        "porc_descuento":this.muestra.porc_descuento,
                                    });
                                    console.log(this.EstatusCambio);
                                    this.solicitudmuestraService.updateSolicitudMuestra(this.EstatusCambio[0]).then();
                                    this.GetMuestra();
                                });                                          
                            }
                            else 
                            {
                                if((this.fecha_llegada >=this.calendarValueEnvio.toISOString().slice(0,10)))
                                {
                                    this.muestraPost1.push({                                  
                                        "id_muestra":this.Id_muestra,
                                        "fecha_envio":this.calendarValueEnvio.toISOString().slice(0,10),
                                        "fecha_llegada":this.fecha_llegada,
                                        "responsable_envio":this.ResponsableEnvio,
                                        "id_medio_transporte":this.dropdownTransporteEnvio.id_medio_transporte,
                                        "nombre_medio_trans":this.NombretransporteEnvio,
                                        "guia":this.ReferenciaEnvio,
                                        "condiciones":this.condicionesMuestraEnvio,
                                        "fecha_real_toma":this.fecha_real_toma,
                                    });
                                    console.log(this.muestraPost1);

                                    this.enviomuestraservice.createEnvioMuestra(this.muestraPost1[0]).then(data => 
                                    {
                                        this.ResponseEnvio = data
                                        this.$toast.add({severity:'success', summary: 'Exito', detail: 'Se ha guardado correctamente. Envío de Kit.', life: 3000});
                                        this.id_envioMuestra=this.ResponseEnvio.id_envio_muestra;
                                        this.EstatusCambio.push({
                                            "id_muestra":this.muestra.id_muestra,
                                            "id_estatus":2,
                                            "motivo_descuento":this.muestra.motivo_descuento,
                                            "porc_descuento":this.muestra.porc_descuento,
                                        });
                                        console.log(this.EstatusCambio);
                                        this.solicitudmuestraService.updateSolicitudMuestra(this.EstatusCambio[0]).then();
                                        this.GetMuestra();
                                    });
                                            
                                    console.log(this.id_envioMuestra);
                                }
                                else
                                {
                                    this.$toast.add({severity:'warn', summary: 'Mensaje', detail: 'La fecha de llegada del kit es menor a la fecha del envío, favor de revisar.', life: 3000});
                                }
                            }
                        }
                        else
                        {
                            if(this.calendarValuellegadaEnvio !=null)
                            {
                                this.fecha_llegada= new Date (this.calendarValuellegadaEnvio);                        
                            }

                            if(this.calendarValueRealEnvio !=null)
                            {
                                this.fecha_real_toma=new Date(this.calendarValueRealEnvio);
                            }
                            
                            if(this.computedDates(this.fecha_llegada)>=this.computedDates(this.calendarValueEnvio))
                            {
                                if(this.computedDates(this.fecha_real_toma)>=this.computedDates(this.calendarValueEnvio))
                                {
                                    this.muestraPost1.push({       
                                        "id_envio_muestra": this.ResponseEnvio.id_envio_muestra,                        
                                        "id_muestra":this.Id_muestra,
                                        "fecha_envio":this.computedDates(this.calendarValueEnvio),
                                        "fecha_llegada":this.computedDates(this.fecha_llegada) ,
                                        "responsable_envio":this.ResponsableEnvio,
                                        "id_medio_transporte":this.dropdownTransporteEnvio.id_medio_transporte,
                                        "nombre_medio_trans":this.NombretransporteEnvio,
                                        "guia":this.ReferenciaEnvio,
                                        "condiciones":this.condicionesMuestraEnvio,
                                        "fecha_real_toma":this.computedDates(this.fecha_real_toma),
                                    });

                                    console.log(this.muestraPost1);
                                    this.enviomuestraservice.updateEnvioMuestra(this.muestraPost1[0]).then();
                                    this.$toast.add({severity:'success', summary: 'Exito', detail: 'Se ha guardado correctamente. Envío de Kit.', life: 3000});
                                }else
                                {
                                    this.$toast.add({severity:'warn', summary: 'Mensaje', detail: 'La fecha real de la toma es menor a la fecha del envío, favor de revisar.', life: 3000});
                                }
                            }
                            else
                            {
                                this.$toast.add({severity:'warn', summary: 'Mensaje', detail: 'La fecha de llegada del kit es menor a la fecha del envío, favor de revisar.', life: 3000});
                            }
                        }                        
                        this.muestraPost1=[];
                        }
                    }
                    

                    if( this.FechaEnvioRecep != null )
                    {
                        let continuar = true;

                        if(this.ResponsableRecep == null || this.ResponsableRecep == ''){
                            this.$toast.add({severity:'warn', summary: 'Mensaje', detail: 'El responsable del envío de la muestra es requerido.', life: 3000});
                            continuar = false;
                        }
                        else if(this.dropdownTransporteRecep == null){
                            this.$toast.add({severity:'warn', summary: 'Mensaje', detail: 'El medio de transporte del envío de la muestra es requerido.', life: 3000});
                            continuar = false;
                        }
                        else if(this.NombretransporteRecep == null || this.NombretransporteRecep == ''){
                            this.$toast.add({severity:'warn', summary: 'Mensaje', detail: 'El nombre del medio de transporte del envío de la muestra es requerido.', life: 3000});
                            continuar = false;
                        }

                        if(continuar){                    
                        if(this.ResponseRecepcion ==null)
                        {
                            if(this.calendarValueLlegadaRecep !=null)
                            {
                                this.fecha_llegada2=this.calendarValueLlegadaRecep;
                            }
                            
                            if(this.computedDates(this.FechaEnvioRecep)>=this.computedDates(this.fecha_llegada))
                            {
                                if(this.fecha_llegada2 == null )
                                {
                                    this.muestraPost2.push({
                                        "id_muestra":this.Id_muestra,
                                        "id_envio_muestra":this.ResponseEnvio.id_envio_muestra,
                                        "fecha_envio":this.computedDates(this.FechaEnvioRecep),
                                        "fecha_llegada":this.fecha_llegada2,
                                        "responsable_envio":this.ResponsableRecep,
                                        "id_medio_transporte":this.dropdownTransporteRecep.id_medio_transporte,
                                        "nombre_medio_trans":this.NombretransporteRecep,
                                        "guia":this.ReferenciaRecep,
                                        "condiciones":this.condicionesMuestraRecep, 
                                    });
                                    console.log(this.muestraPost2);

                                    this.enviomuestraservice.createRecepcionMuestra(this.muestraPost2[0]).then(data => this.ResponseRecepcion = data);
                                    this.$toast.add({severity:'success', summary: 'Exito', detail: 'Se ha guardado correctamente. Recepción de Muestras.', life: 3000});
                                }
                                else 
                                {
                                    if(this.computedDates(this.FechaEnvioRecep)>=this.computedDates(this.fecha_llegada2))
                                    {
                                        this.muestraPost2.push({
                                            "id_muestra":this.Id_muestra,
                                            "id_envio_muestra":this.ResponseEnvio.id_envio_muestra,
                                            "fecha_envio":this.computedDates(this.FechaEnvioRecep),
                                            "fecha_llegada":this.computedDates(this.fecha_llegada2),
                                            "responsable_envio":this.ResponsableRecep,
                                            "id_medio_transporte":this.dropdownTransporteRecep.id_medio_transporte,
                                            "nombre_medio_trans":this.NombretransporteRecep,
                                            "guia":this.ReferenciaRecep,
                                            "condiciones":this.condicionesMuestraRecep, 
                                        });
                                        console.log(this.muestraPost2);
                                        this.enviomuestraservice.createRecepcionMuestra(this.muestraPost2[0]).then(data => this.ResponseRecepcion = data);
                                        this.$toast.add({severity:'success', summary: 'Exito', detail: 'Se ha guardado correctamente. Recepción de Muestras.', life: 3000});

                                    }
                                    else
                                    {
                                        this.$toast.add({severity:'warn', summary: 'Mensaje', detail: 'La fecha de llegada de las muestras es menor a la fecha del envío, favor de revisar', life: 3000});
                                    }

                                }
                            }
                            else
                            {
                                 this.$toast.add({severity:'warn', summary: 'Mensaje', detail: 'La fecha de envío de las muestras es menor a la fecha de llegada del kit, favor de revisar', life: 3000});
                            }
                        }
                        else
                        {
                            if(this.calendarValueLlegadaRecep !=null)
                            {
                                this.fecha_llegada2=this.calendarValueLlegadaRecep;
                            }

                            if(this.computedDates(this.fecha_llegada2)>=this.computedDates(this.FechaEnvioRecep))
                            {
                                this.muestraPost2.push({
                                    "id_recepcion_muestra":this.ResponseRecepcion.id_recepcion_muestra,
                                    "id_muestra":this.Id_muestra,
                                    "fecha_envio":this.computedDates(this.FechaEnvioRecep),
                                    "fecha_llegada":this.computedDates(this.calendarValueLlegadaRecep),
                                    "responsable_envio":this.ResponsableRecep,
                                    "id_medio_transporte":this.dropdownTransporteRecep.id_medio_transporte,
                                    "nombre_medio_trans":this.NombretransporteRecep,
                                    "guia":this.ReferenciaRecep,
                                    "condiciones":this.condicionesMuestraRecep, 
                                });

                                this.enviomuestraservice.updateRecepcionMuestra(this.muestraPost2[0]).then();
                                this.$toast.add({severity:'success', summary: 'Exito', detail: 'Se ha guardado correctamente Recepción de Muestras', life: 3000});
                            }
                            else
                            {
                                this.$toast.add({severity:'warn', summary: 'Mensaje', detail: 'La fecha de llegada de las muestras es menor a la fecha del envío, favor de revisar', life: 3000});
                            }
                        }
                        this.muestraPost2=[];
                    }
                    }
                }
            },
            computedDates (value) {
            return moment(value).format('YYYY-MM-DD')
          }
		}
    }
     
</script>


<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
</style>
