import axios from 'axios';

export default class SolicitudMuestraService {

	getSolicitudMuestra() {
		//return axios.get('assets/layout/data/usuarios.json').then(res => res.data.data);
		//const url = `https://sam.apicontrol.website/api/api/muestras`;
		const url = `/api/muestras`;
		return axios.get(url).then(res=>res.data.data);
	}
	
	createSolicitudMuestraPadecimiento(solicitudmuestra) {
		//return axios.get('assets/layout/data/usuarios.json').then(res => res.data.data);
		//const url = `https://sam.apicontrol.website/api/api/muestrapadecimientos`;
		const url = `/api/muestrapadecimientos`;
		return axios.post(url,solicitudmuestra).then(res=>res.data.data);
    }
    
    createSolicitudMuestra(solicitudmuestra) {
		//return axios.get('assets/layout/data/usuarios.json').then(res => res.data.data);
        //const url = `https://sam.apicontrol.website/api/api/muestras`;
		const url = `/api/muestras`;
        
        const res= axios.post(url,solicitudmuestra).then(res=>res.data)     

		return res;
    }
    createSolicitudServicio(solicitudmuestra) {
		//return axios.get('assets/layout/data/usuarios.json').then(res => res.data.data);
        //const url = `https://sam.apicontrol.website/api/api/muestraservicios`;
		const url = `/api/muestraservicios`;
        
        const res= axios.post(url,solicitudmuestra).then(res=>res.data)     

		return res;
	}

	deleteSolicitudServicio(solicitudmuestra) {
		//return axios.get('assets/layout/data/usuarios.json').then(res => res.data.data);
        //const url = `https://sam.apicontrol.website/api/api/muestraservicios`;
		const url = `/api/muestraserviciosbyvalue`;
        
        const res= axios.delete(url,{data:solicitudmuestra}).then(res=>res.data)     

		return res;
	}
	
	updateSolicitudMuestra(solicitudmuestra) {
		//return axios.get('assets/layout/data/usuarios.json').then(res => res.data.data);
		//const url = `https://sam.apicontrol.website/api/api/muestras/${solicitudmuestra.id_muestra}`;
		const url = `/api/muestras/${solicitudmuestra.id_muestra}`;
		return axios.put(url,solicitudmuestra).then(res=>res.data.data);
	}
	
	deleteSolicitudMuestra(solicitudmuestra) {
		//return axios.get('assets/layout/data/usuarios.json').then(res => res.data.data);
		//const url = `https://sam.apicontrol.website/api/api/muestras/${solicitudmuestra.id_muestra}`;
		const url = `/api/muestras/${solicitudmuestra.id_muestra}`;
		return axios.delete(url).then(res=>res.data.data);
	}
	
	ImprimirMuestraSolicitud(muestra) {
		//return axios.get('assets/layout/data/usuarios.json').then(res => res.data.data);
		const url = `https://apicontrol.be/sgcells/print.php`;
		return axios.delete(url,muestra).then(res=>res.data.data);
    }
}