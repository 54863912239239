import axios from 'axios';

export default class EnvioMuestraService {

	getUEnvioMuestra() {
		//return axios.get('assets/layout/data/usuarios.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/enviomuestras`;
		const url = `/api/enviomuestras`;
		return axios.get(url).then(res=>res.data.data);
	}

	getUEnvioMuestraID(enviomuestras) {
		//return axios.get('assets/layout/data/usuarios.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/enviomuestras/${enviomuestras}`;
		const url = `/api/enviomuestras/${enviomuestras}`;
		return axios.get(url).then(res=>res.data.data);
	}

	getURecepMuestraID(enviomuestras) {
		//return axios.get('assets/layout/data/usuarios.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/recepcionmuestras/${enviomuestras}`;
		const url = `/api/recepcionmuestras/${enviomuestras}`;
		return axios.get(url).then(res=>res.data.data);
	}
	
	createEnvioMuestra(enviomuestras) {
		//return axios.get('assets/layout/data/usuarios.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/enviomuestras`;
		const url = `/api/enviomuestras`;
		return axios.post(url,enviomuestras).then(res=>res.data.data);
	}
	
	updateEnvioMuestra(enviomuestras) {
		//return axios.get('assets/layout/data/usuarios.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/enviomuestras/${enviomuestras.id_envio_muestra}`;
		const url = `/api/enviomuestras/${enviomuestras.id_envio_muestra}`;
		return axios.put(url,enviomuestras).then(res=>res.data.data);
	}

	createRecepcionMuestra(enviomuestras) {
		//return axios.get('assets/layout/data/usuarios.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/recepcionmuestras`;
		const url = `/api/recepcionmuestras`;
		return axios.post(url,enviomuestras).then(res=>res.data.data);
	}
	
	updateRecepcionMuestra(enviomuestras) {
		//return axios.get('assets/layout/data/usuarios.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/recepcionmuestras/${enviomuestras.id_recepcion_muestra}`;
		const url = `/api/recepcionmuestras/${enviomuestras.id_recepcion_muestra}`;
		return axios.put(url,enviomuestras).then(res=>res.data.data);
	}
	
	deleteEnvioMuestra(enviomuestras) {
		//return axios.get('assets/layout/data/usuarios.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/enviomuestras/${enviomuestras.id_envio_muestra}`;
		const url = `/api/enviomuestras/${enviomuestras.id_envio_muestra}`;
		return axios.delete(url).then(res=>res.data.data);
    }
}